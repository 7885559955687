import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectIsUserAdmin } from 'src/store/currentUserSlice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { closeDrawer, selectDrawerOpen } from 'src/store/settingsSlice';
import styles from './MenuDrawer.module.scss';
import { menuGroups } from './MenuDrawerData';

export function MenuDrawer() {
    const drawerOpen = useAppSelector(selectDrawerOpen);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isAdmin = useAppSelector(selectIsUserAdmin);

    const handleDrawerClose = useCallback(() => {
        dispatch(closeDrawer());
    }, [dispatch]);

    const handleMenuClick = useCallback(
        (route: string) => {
            navigate(route);
            dispatch(closeDrawer());
        },
        [navigate, dispatch]
    );

    return (
        <Drawer variant="temporary" anchor="left" open={drawerOpen} onClose={handleDrawerClose} className={`${styles.drawer}`}>
            <List>
                {menuGroups.map((menuGroup, groupIndex) => {
                    if (!isAdmin && !!menuGroup.needsAdmin) return null;

                    return (
                        <React.Fragment key={`menuGroup${groupIndex}`}>
                            <ListSubheader>{menuGroup.heading}</ListSubheader>
                            {menuGroup.items.map((menuItem, index) => (
                                <ListItem key={`menuItem${groupIndex}-${index}`}>
                                    <ListItemButton onClick={() => handleMenuClick(menuItem.route)}>
                                        <ListItemIcon>
                                            <menuItem.icon />
                                        </ListItemIcon>
                                        <ListItemText>{menuItem.text}</ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </React.Fragment>
                    );
                })}
            </List>
        </Drawer>
    );
}
