import { removeBy } from '@khel/kutils';
import { Platform } from '@functions/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getApp } from 'firebase/app';
import { getFirestore, onSnapshot, query } from 'firebase/firestore';
import { batch } from 'react-redux';
import { constraint, tCollection } from 'src/hooks/firestoreHooks';
import type { AppDispatch, RootState } from './store';

interface GameDataState {
    activePlatforms: Platform[];
}

const initialState: GameDataState = {
    activePlatforms: new Array<Platform>(),
};

// Create the gameData slice and the reducers
const gameDataSlice = createSlice({
    name: 'gameData',
    initialState,
    reducers: {
        modifiedActivePlatform: (state, action: PayloadAction<Platform>) => {
            const index = state.activePlatforms.findIndex((platform) => platform.id === action.payload.id);
            if (index !== -1) state.activePlatforms[index] = action.payload;
        },

        addedActivePlatform: (state, action: PayloadAction<Platform>) => {
            state.activePlatforms.push(action.payload);
        },

        removedActivePlatform: (state, action: PayloadAction<Platform>) => {
            removeBy(state.activePlatforms, (platform) => platform.id === action.payload.id);
        },
    },
});

export const { modifiedActivePlatform, addedActivePlatform, removedActivePlatform } = gameDataSlice.actions;
export const selectActivePlatforms = (state: RootState) => state.gameData.activePlatforms;
export default gameDataSlice.reducer;

// Subscribe to real time updates for active platforms and dispatch actions
// as items are added/modified/removed
export function activePlatformsListener() {
    return (dispatch: AppDispatch) => {
        const db = getFirestore(getApp());

        const platformQuery = query(tCollection<Platform>(db, 'platforms'), constraint<Platform>('active', '!=', false));
        return onSnapshot(platformQuery, (querySnapshot) => {
            batch(() => {
                querySnapshot.docChanges().forEach((change) => {
                    dispatch({ type: `gameData/${change.type}ActivePlatform`, payload: change.doc.data() });
                });
            });
        });
    };
}
