import { AuthRequest, AuthResponse } from '@functions/types';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { getApp } from 'firebase/app';

export default function OauthRedirect() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const doDiscordLogin = async () => {
            const authCode = searchParams.get('code');
            const state = searchParams.get('state');

            if (authCode == null || state == null) {
                navigate('/', { replace: true });
                return;
            }

            try {
                const functions = getFunctions(getApp());
                const doDiscordAuthentication = httpsCallable<AuthRequest, AuthResponse>(functions, 'doDiscordAuthenticationV2');

                const response = await doDiscordAuthentication({ state, authCode });
                const auth = getAuth(getApp());
                await signInWithCustomToken(auth, response.data.jwt);
            } catch (error: any) {
                console.warn(`Error while authenticating:`, error.message);
            }

            navigate('/', { replace: true });
        };

        doDiscordLogin();
    }, [navigate, searchParams]);

    return (
        <Stack direction="column" alignItems="center" spacing={4} sx={{ mt: 20 }}>
            <Typography variant="h3">Authenticating...</Typography>
            <CircularProgress />
        </Stack>
    );
}
