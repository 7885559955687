import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAuth, ParsedToken } from 'firebase/auth';
import { UserAccountData, UserDiscordInfo, UserProfile } from '@functions/types';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { getApp } from 'firebase/app';
import { tCollection } from 'src/hooks/firestoreHooks';
import type { AppDispatch, RootState } from './store';

type UserStatus = 'SignedIn' | 'SignedOut' | 'Uninitialised';

interface CurrentUserState {
    userProfile: undefined | null | UserProfile;
    userStatus: UserStatus;
    userClaims: ParsedToken | null;
    userAccountData: UserAccountData | null | undefined;
    error: string;
}

const initialState: CurrentUserState = {
    userAccountData: undefined,
    userProfile: undefined,
    userStatus: 'Uninitialised',
    userClaims: null,
    error: '',
};

export const fetchCurrentUser = createAsyncThunk('currentUser/fetchUser', async (getUserRequest: () => Promise<UserDiscordInfo>) => {
    const user = await getUserRequest();
    return user;
});

export const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState,
    reducers: {
        updateUserAccountData: (state, action: PayloadAction<UserAccountData>) => {
            state.userAccountData = action.payload;
        },

        updateUserProfile: (state, action: PayloadAction<UserProfile>) => {
            state.userProfile = action.payload;
        },

        updateUserClaims: (state, action: PayloadAction<ParsedToken | null>) => {
            state.userClaims = action.payload;
        },

        updateUserStatus: (state, action: PayloadAction<UserStatus>) => {
            state.userStatus = action.payload;
        },

        logoutUser: (state) => {
            state.userProfile = null;
            state.userClaims = null;
            state.userAccountData = null;
            state.userStatus = 'SignedOut';
        },
    },
});

export const { updateUserProfile, updateUserAccountData, updateUserClaims, logoutUser, updateUserStatus } = currentUserSlice.actions;

export const selectUserProfile = (state: RootState) => state.currentUser.userProfile;
export const selectUserAccountData = (state: RootState) => state.currentUser.userAccountData;
export const selectUserStatus = (state: RootState) => state.currentUser.userStatus;
export const selectUserClaims = (state: RootState) => state.currentUser.userClaims;
export const selectIsUserAdmin = (state: RootState) => !!state.currentUser.userClaims?.admin;

export default currentUserSlice.reducer;

// Subscribe to real time updates for active platforms and dispatch actions
// as items are added/modified/removed
export function userProfileListener(uid: string) {
    return (dispatch: AppDispatch) => {
        const db = getFirestore(getApp());

        const userProfileDocRef = doc(tCollection<UserProfile>(db, 'userProfiles'), uid);
        return onSnapshot(userProfileDocRef, (snapshot) => {
            if (!snapshot.exists()) {
                console.warn("Something has gone very wrong, could not find the current user's profile. Signing user out.", { uid });
                getAuth(getApp()).signOut();
            } else dispatch(updateUserProfile(snapshot.data()));
        });
    };
}
