import { CircularProgress, Container, Stack, Typography } from '@mui/material';

export default function LoadingScreen() {
    return (
        <Container maxWidth={false}>
            <Stack direction="column" alignItems="center" spacing={4} sx={{ mt: 20 }}>
                <Typography variant="h2">Initialising...</Typography>
                <CircularProgress size="4em" />
            </Stack>
        </Container>
    );
}
