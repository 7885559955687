import { differenceBy, sortBy, union } from '@khel/kutils';
import { Platform, UpdateData } from '@functions/types';
import { Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Grid, Stack, Typography } from '@mui/material';
import { getApp } from 'firebase/app';
import { doc, getDocs, getFirestore, query, writeBatch } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { igdbImageUrl } from 'src/classes/utils';
import { MultiSelectListBoxItem } from 'src/components/MultiSelectListBox';
import TransferList from 'src/components/TransferList';
import { selectIsUserAdmin } from 'src/store/currentUserSlice';
import { useAppSelector } from 'src/store/hooks';
import { constraint, useFirestoreCollection } from 'src/hooks/firestoreHooks';
import { Forbidden } from '../shared/Forbidden';

export default function Platforms() {
    const [platforms, setPlatforms] = useState<Platform[]>(new Array<Platform>());
    const [availableItems, setAvailableItems] = useState<MultiSelectListBoxItem[]>(new Array<MultiSelectListBoxItem>());
    const [selectedItems, setSelectedItems] = useState<MultiSelectListBoxItem[]>(new Array<MultiSelectListBoxItem>());
    const isAdmin = useAppSelector(selectIsUserAdmin);
    const [isChanged, setIsChanged] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const platformsCollection = useFirestoreCollection<Platform>('platforms');

    // Load all the platforms
    useEffect(() => {
        if (!isAdmin) return;

        async function getPlatforms() {
            const platformQuery = query(platformsCollection, constraint<Platform>('igdbPlatformId', '!=', 0));
            const snapshot = await getDocs(platformQuery);
            setPlatforms(snapshot.docs.map((docSnapshot) => docSnapshot.data()));
        }

        getPlatforms();
    }, [isAdmin, platformsCollection]);

    // Filter the platforms into available and selected ones and build the menu list items
    useEffect(() => {
        const newAvailableItems = Array<MultiSelectListBoxItem>();
        const newSelectedItems = Array<MultiSelectListBoxItem>();

        platforms.forEach((platform) => {
            if (platform.active) {
                newSelectedItems.push({ id: platform.id, text: platform.name, image: igdbImageUrl(platform.logoImageHash, 'micro') });
            } else newAvailableItems.push({ id: platform.id, text: platform.name, image: igdbImageUrl(platform.logoImageHash, 'micro') });
        });

        setAvailableItems(sortBy(newAvailableItems, 'text'));
        setSelectedItems(sortBy(newSelectedItems, 'text'));
    }, [platforms]);

    const handleTransferLeft = useCallback(
        (transferredPlatforms: MultiSelectListBoxItem[]) => {
            // Add transferred platforms back into the available list and remove them from the selected list
            setAvailableItems(
                sortBy(
                    union(availableItems, transferredPlatforms, (element) => element.id),
                    'text'
                )
            );
            setSelectedItems(differenceBy(selectedItems, transferredPlatforms, (platform) => platform.id));
            setIsChanged(true);
        },
        [availableItems, selectedItems]
    );

    const handleTransferRight = useCallback(
        (transferredPlatforms: MultiSelectListBoxItem[]) => {
            // Add transferred platforms to the selected list and remove them from the available list
            setSelectedItems(
                sortBy(
                    union(selectedItems, transferredPlatforms, (element) => element.id),
                    'text'
                )
            );
            setAvailableItems(differenceBy(availableItems, transferredPlatforms, (platform) => platform.id));
            setIsChanged(true);
        },
        [availableItems, selectedItems]
    );

    const handleSave = useCallback(() => {
        async function saveChanges() {
            // Perform a batched write to write out all the new statuses for the platforms
            const db = getFirestore(getApp());
            const batch = writeBatch(db);

            // Add updates to the batch for items in the available list
            availableItems.forEach((item) => {
                const data: UpdateData<Platform> = { active: false };
                batch.update(doc(platformsCollection, item.id), data);
            });

            // Add updates to the batch for items in the selected list
            selectedItems.forEach((item) => {
                const data: UpdateData<Platform> = { active: true };
                batch.update(doc(platformsCollection, item.id), data);
            });

            try {
                await batch.commit();
                enqueueSnackbar('Successfully saved your platform changes.', { variant: 'success' });
                setIsChanged(false);
            } catch (error: any) {
                console.error('Error while saving platforms', error.toJSON() ? error.toJSON() : { ...error });
                enqueueSnackbar('An error occured while trying to save your platform changes.', { variant: 'error' });
            } finally {
                setIsSaving(false);
            }
        }

        setIsSaving(true);
        saveChanges();
    }, [availableItems, enqueueSnackbar, selectedItems, platformsCollection]);

    // If the user isn't an admin, they can't see this page
    if (!isAdmin) return <Forbidden />;

    return (
        <Stack direction="column">
            <Grid container columns={3}>
                <Grid xs item />
                <Grid xs="auto" item>
                    <Stack direction="column" alignItems="flex-end" spacing={3}>
                        <Typography variant="h4" gutterBottom alignSelf="flex-start" sx={{ mt: 2 }}>
                            Edit Platforms
                        </Typography>
                        <TransferList
                            disabled={isSaving}
                            leftItems={availableItems}
                            rightItems={selectedItems}
                            leftLabel="Available Platforms"
                            rightLabel="Selected Platforms"
                            minListWidth={450}
                            minListHeight={350}
                            maxListHeight={500}
                            onTransferToLeft={handleTransferLeft}
                            onTransferToRight={handleTransferRight}
                        />
                        <LoadingButton startIcon={<Save />} loading={isSaving} disabled={!isChanged} onClick={handleSave} variant="contained">
                            Save Changes
                        </LoadingButton>
                    </Stack>
                </Grid>
                <Grid xs item />
            </Grid>
        </Stack>
    );
}
