import { FirestoreCollectionPath, FirestorePath } from '@functions/types';
import { getApp } from 'firebase/app';
import {
    CollectionReference,
    DocumentData,
    DocumentReference,
    Firestore,
    QueryDocumentSnapshot,
    QueryFieldFilterConstraint,
    WhereFilterOp,
    collection,
    doc,
    getFirestore,
    where,
} from 'firebase/firestore';

// Create a to/from converter for type T
const converter = <T>() => ({
    toFirestore: (data: T) => data as DocumentData,
    fromFirestore: (snap: QueryDocumentSnapshot) => snap.data() as T,
});

// Used to return a typed collection reference
export const tCollection = <T>(db: Firestore, collectionPath: FirestoreCollectionPath) => collection(db, collectionPath).withConverter(converter<T>());
// const typedSubCollection = <T>(parentDocRef: admin.firestore.DocumentReference, collectionPath: FirestoreCollectionPath) =>
//     parentDocRef.collection(collectionPath).withConverter(converter<T>());

// Doc helper for making a type-safe document reference
export const tDoc = <T>(db: Firestore, path: FirestoreCollectionPath, ...pathSegments: string[]): DocumentReference<T> =>
    doc(db, path, ...pathSegments).withConverter(converter<T>());

// helper to make type-safe paths
export const fieldPath = <T>(pathName: FirestorePath<T>) => pathName as string;

// helper to make type-safe filters for where clauses
export const constraint = <T>(fieldName: FirestorePath<T>, operator: WhereFilterOp, value: any): QueryFieldFilterConstraint => where(fieldName as string, operator, value);

const collections = new Map<FirestoreCollectionPath, CollectionReference>();

export function useFirestoreCollection<T extends object>(collectionPath: FirestoreCollectionPath) {
    if (!collections.has(collectionPath)) {
        const newCollection = tCollection<T>(getFirestore(getApp()), collectionPath);
        collections.set(collectionPath, newCollection);
    }

    return collections.get(collectionPath) as CollectionReference<T>;
}
