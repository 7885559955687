import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material';
import type Theme from '@mui/material';

interface StringAvatarProps {
    name: string;
}

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function createStringAvatar(name: string, theme: Theme.Theme) {
    const splitName = name.split(' ');

    return {
        sx: {
            bgcolor: stringToColor(name),
            color: theme.palette.getContrastText(stringToColor(name)),
        },
        children: `${splitName[0] !== undefined ? splitName[0][0] : ''}${splitName[1] !== undefined ? splitName[1][0] : ''}`,
    };
}

export default function StringAvatar({ name }: StringAvatarProps) {
    const theme = useTheme();

    return <Avatar {...createStringAvatar(name, theme)} />;
}
