import { Outlet } from 'react-router-dom';
import Container from '@mui/material/Container';
import { Paper, Toolbar } from '@mui/material';
import { useAppSelector } from 'src/store/hooks';
import { selectUserAccountData, selectUserProfile, selectUserStatus } from 'src/store/currentUserSlice';
import LoadingScreen from 'src/components/LoadingScreen';
import { useCallback } from 'react';
import HeaderBar from '../components/HeaderBar';
import styles from '../styles/App.module.scss';

export default function Main() {
    const userStatus = useAppSelector(selectUserStatus);
    const userProfile = useAppSelector(selectUserProfile);
    const currentUser = useAppSelector(selectUserAccountData);

    const isLoading = useCallback(
        () => userStatus === 'Uninitialised' || currentUser === undefined || userProfile === undefined,
        [currentUser, userStatus, userProfile]
    );

    return (
        <>
            {!isLoading() && (
                <Container maxWidth="xl">
                    <div className={styles.App}>
                        <HeaderBar />
                        <Toolbar />
                        <Paper elevation={1} sx={{ padding: 2, minHeight: 850 }}>
                            <Outlet />
                        </Paper>
                    </div>
                </Container>
            )}
            {isLoading() && <LoadingScreen />}
        </>
    );
}
