import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './routes/Main';
import OauthRedirect from './routes/auth/oauthRedirect';
import Auth from './routes/auth/auth';
import Platforms from './routes/admin/platforms';
import Admin from './routes/admin/admin';
import { NotFound } from './routes/shared/NotFound';
// import FunctionTest from './routes/admin/functionTest';
import ManageProfile from './routes/admin/profile';

function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Main />}>
                    <Route path="admin" element={<Admin />}>
                        <Route path="platforms" element={<Platforms />} />
                        {/* <Route path="functionstest" element={<FunctionTest />} /> */}
                        <Route path="profile" element={<ManageProfile />} />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Route>

                <Route path="/auth" element={<Auth />}>
                    <Route path="oauthRedirect" element={<OauthRedirect />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default Router;
