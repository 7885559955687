import { hasValue } from '@khel/kutils';
import { UserAccountData, UserProfile } from '@functions/types';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getApp, initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth, onAuthStateChanged } from 'firebase/auth';
import { connectFirestoreEmulator, doc, getFirestore, setDoc, Unsubscribe } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { SystemZone } from 'luxon';
import { SnackbarProvider } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import Router from './Router';
import {
    logoutUser,
    selectUserProfile,
    selectUserStatus,
    updateUserClaims,
    updateUserAccountData,
    updateUserStatus,
    userProfileListener,
    selectUserAccountData,
} from './store/currentUserSlice';
import { activePlatformsListener } from './store/gameDataSlice';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { useFirestoreCollection } from './hooks/firestoreHooks';

// Firebase configuration settings
const firebaseConfig = {
    apiKey: 'AIzaSyDWyG5mJmAYO2jRSi5PJf5orXqFHggu3bU',
    authDomain: 'hijinks-20dba.firebaseapp.com',
    projectId: 'hijinks-20dba',
    storageBucket: 'hijinks-20dba.appspot.com',
    messagingSenderId: '976702567872',
    appId: '1:976702567872:web:30fc2e1a380e5a4fb60425',
    measurementId: 'G-ZVJ8VH363V',
};

// Initialise the firebase app, its then passed to our <App> and stored as a context
// so it can be accessed wherever its needed
const app = initializeApp(firebaseConfig);

// If we're running in development mode, and emulators are going, connect them up
if (process.env.NODE_ENV === 'development') {
    if (process.env.REACT_APP_EMULATED !== undefined) {
        console.log('Connecting emulators...');
        connectAuthEmulator(getAuth(app), 'http://localhost:9099');
        connectFirestoreEmulator(getFirestore(app), 'localhost', 8080);
        connectFunctionsEmulator(getFunctions(app), 'localhost', 5001);
    }
}

export default function App() {
    const dispatch = useAppDispatch();
    const userStatus = useAppSelector(selectUserStatus);
    const userProfile = useAppSelector(selectUserProfile);
    const currentUser = useAppSelector(selectUserAccountData);
    const userProfilesCollection = useFirestoreCollection<UserProfile>('userProfiles');

    // Subscribe for data updates
    useEffect(() => {
        const unsubFunctions = new Array<Unsubscribe>();

        // Subscribe for updates to active platforms list
        unsubFunctions.push(dispatch(activePlatformsListener()));

        // Subscribe for updates to the user profile when a user is signed in
        if (hasValue(currentUser) && userStatus === 'SignedIn') unsubFunctions.push(dispatch(userProfileListener(currentUser.uid)));

        // Clean up by unsubscribing from everything we're subscribed to
        return () => {
            unsubFunctions.forEach((unsubscribe) => {
                unsubscribe();
            });
        };
    }, [dispatch, currentUser, userStatus]);

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: userProfile?.siteOptions?.theme === 'light' ? 'light' : 'dark',
                },
            }),
        [userProfile]
    );

    // Set up an observer to catch the current user when login state changes
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(getAuth(app), (user) => {
            // If a user logged in, get the custom claims for the user and store them
            // in the redux state so we don't have to query them every time to check for permissions, etc
            console.log('Auth state changed');
            dispatch(updateUserAccountData(user?.toJSON() as UserAccountData));

            if (user !== null) {
                dispatch(updateUserStatus('SignedIn'));
                user.getIdTokenResult()
                    .then((idTokenResult) => {
                        dispatch(updateUserClaims(idTokenResult.claims));
                    })
                    .catch((error) => {
                        console.warn('Error while checking for user claims: ', error);
                        dispatch(updateUserClaims(null));
                    });
            } else dispatch(logoutUser());
        });

        // Cleanup by unsubscribing the observer
        return () => unsubscribe();
    }, [dispatch]);

    // Initialise the user's profile with some defaults if this is a fresh login
    useEffect(() => {
        async function initialiseProfileDefaults() {
            if (currentUser === null || currentUser === undefined) {
                console.warn('Could not initialise user profile for new user.');
                getAuth(getApp()).signOut();
                return Promise.reject();
            }

            const defaultProfile: UserProfile = {
                uid: currentUser.uid,
                siteOptions: { theme: 'dark' },
                timezone: SystemZone.instance.name,
            };

            try {
                const docRef = doc(userProfilesCollection, currentUser.uid);
                setDoc(docRef, defaultProfile);
            } catch (error: any) {
                console.warn('Error while attempting to initialise profile for new user.', error.toJSON ? error.toJSON() : { ...error });
                getAuth(getApp()).signOut();
                return Promise.reject();
            }
        }

        if (userProfile !== null && userProfile !== undefined && !userProfile.timezone) initialiseProfileDefaults();
    }, [userProfile, currentUser, dispatch, userProfilesCollection]);

    return (
        <SnackbarProvider maxSnack={3}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Router />
            </ThemeProvider>
        </SnackbarProvider>
    );
}
