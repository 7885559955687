import { Avatar, Checkbox, Divider, FormControlLabel, List, ListItemAvatar, ListItemButton, ListItemText, Paper, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { intersection } from '@khel/kutils';
import StringAvatar from './StringAvatar';

export interface MultiSelectListBoxItem {
    id: string;
    text: string;
    image?: string | null;
}

interface MultiSelectListBoxProps {
    items: MultiSelectListBoxItem[];
    onChange?: (selectedItems: MultiSelectListBoxItem[]) => void;
    minWidth?: number;
    minHeight?: number;
    maxHeight?: number;
    label?: string;
    disabled?: boolean;
}

export default function MultiSelectListBox({ items, onChange, minWidth, minHeight, maxHeight, label, disabled }: MultiSelectListBoxProps) {
    const [selectAllChecked, setSelectAll] = useState(false);

    // A list indexed by the id of the item that we can use to cross-reference and see quickly which items are selected
    const [selectedItems, setSelectedItems] = useState<Set<MultiSelectListBoxItem>>(new Set());

    // Refresh selections if items have changed
    useEffect(() => {
        // Items changed so remove any selected items that no longer exist
        setSelectedItems((currentSelectedItems) => {
            // Only keep selected items that still exist in the items list
            const updatedSelectedItems = intersection([...currentSelectedItems], items);

            return new Set(updatedSelectedItems);
        });

        if (items.length === 0) setSelectAll(false);
    }, [items]);

    const handleSelectAll = useCallback(() => {
        const newSelectedItems: Set<MultiSelectListBoxItem> = new Set();

        // Flip the selected flag
        setSelectAll(!selectAllChecked);

        // We're checking the box, so fill an array to say every item is selected
        if (!selectAllChecked) {
            items.forEach((item) => {
                newSelectedItems.add(item);
            });
        }

        setSelectedItems(newSelectedItems);

        if (onChange) {
            if (newSelectedItems.size === 0) onChange(new Array<MultiSelectListBoxItem>());
            else onChange([...newSelectedItems]);
        }
    }, [selectAllChecked, items, onChange]);

    const handleListItemClick = useCallback(
        (event: React.MouseEvent<HTMLElement>, item: MultiSelectListBoxItem) => {
            const newSelectedItems = new Set(selectedItems);

            // If the item is already selected, then unselect it, or vice versa
            if (selectedItems.has(item)) newSelectedItems.delete(item);
            else newSelectedItems.add(item);

            setSelectedItems(newSelectedItems);

            // If all items are now selected, tick the 'Select All' box
            setSelectAll(newSelectedItems.size === items.length && items.length !== 0);

            if (onChange) {
                // Return list of selected items to the onChange handler
                onChange([...newSelectedItems]);
            }
        },
        [selectedItems, onChange, items]
    );

    return (
        <Paper elevation={3}>
            <Typography variant="subtitle1" component="h1" textAlign="center" paddingTop={1} paddingBottom={1}>
                {label}
            </Typography>
            <Divider />
            <Stack direction="row" justifyContent="flex-end">
                <FormControlLabel
                    disabled={items.length === 0 || disabled}
                    label={selectAllChecked ? 'Unselect All' : 'Select All'}
                    labelPlacement="start"
                    control={<Checkbox checked={selectAllChecked} onChange={handleSelectAll} sx={{ mr: 1 }} />}
                />
            </Stack>
            <Divider />
            <List
                sx={{
                    minWidth: minWidth ? `${minWidth}px` : 0,
                    minHeight: minHeight ? `${minHeight}px` : 0,
                    maxHeight: maxHeight ? `${maxHeight}px` : 'none',
                    overflow: 'auto',
                }}>
                {items.map((item) => (
                    <ListItemButton disabled={disabled} onClick={(event) => handleListItemClick(event, item)} key={item.id} selected={selectedItems.has(item)}>
                        <ListItemAvatar>
                            {item.image && <Avatar alt={item.text} src={item.image} />}
                            {!item.image && <StringAvatar name={item.text} />}
                        </ListItemAvatar>
                        <ListItemText primary={item.text} />
                    </ListItemButton>
                ))}
            </List>
        </Paper>
    );
}
