import { Info } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

interface IconHelperTextProps {
    text: string;
    icon?: 'info';
}

export default function IconHelperText({ text, icon }: IconHelperTextProps) {
    return (
        <Stack direction="row" component="span" sx={{ paddingTop: 0.25 }}>
            {(icon === undefined || icon === 'info') && <Info sx={{ width: '0.65em', height: '0.65em' }} />}
            <Typography variant="caption" sx={{ paddingLeft: 0.5 }}>
                {text}
            </Typography>
        </Stack>
    );
}
