import { AppBar, CircularProgress, IconButton, Link, Stack, Toolbar, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import { Link as RouterLink } from 'react-router-dom';
import { useCallback } from 'react';
import { selectUserStatus } from 'src/store/currentUserSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import DiscordLoginButton from './DiscordLoginButton';
import LoggedInBadge from './LoggedInBadge';
import { openDrawer } from '../store/settingsSlice';
import { MenuDrawer } from './MenuDrawer/MenuDrawer';

export default function HeaderBar() {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const currentUserStatus = useAppSelector(selectUserStatus);

    const handleDrawerOpen = useCallback(() => {
        dispatch(openDrawer());
    }, [dispatch]);

    return (
        <Box sx={{ width: '100%' }}>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton onClick={handleDrawerOpen} edge="start" sx={{ mr: theme.spacing(2) }}>
                        <MenuIcon />
                    </IconButton>
                    <Stack direction="column" spacing={-1.5} sx={{ flexGrow: 1, ml: 4 }}>
                        <Link
                            variant="h3"
                            color="inherit"
                            component={RouterLink}
                            to="/"
                            underline="none"
                            noWrap
                            sx={{
                                fontFamily: 'Slackey',
                                fontSize: `3rem`,
                                lineHeight: 'normal',
                                letterSpacing: `0.1em`,
                            }}>
                            Hijinks
                        </Link>
                    </Stack>
                    {currentUserStatus === 'Uninitialised' && <CircularProgress />}
                    {currentUserStatus === 'SignedOut' && <DiscordLoginButton />}
                    {currentUserStatus === 'SignedIn' && <LoggedInBadge />}
                </Toolbar>
            </AppBar>
            <MenuDrawer />
        </Box>
    );
}
