import { Logout, ManageAccounts } from '@mui/icons-material';
import { Avatar, Divider, IconButton, ListItemIcon, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectUserAccountData } from 'src/store/currentUserSlice';
import { useAppSelector } from 'src/store/hooks';

export default function LoggedInBadge() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const currentUser = useAppSelector(selectUserAccountData);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleLogout = useCallback(() => {
        setAnchorEl(null);
        getAuth(getApp()).signOut();
    }, []);

    const handleProfile = useCallback(() => {
        setAnchorEl(null);
        navigate('/admin/profile');
    }, [navigate]);

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <IconButton onClick={handleMenuOpen}>
                <Avatar alt={currentUser?.displayName || ''} src={`${currentUser?.photoURL}?size=48`} />
            </IconButton>
            <Typography variant="body1" sx={{ paddingLeft: '5px' }}>
                {currentUser?.displayName}
            </Typography>
            <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
                <MenuItem onClick={handleProfile}>
                    <ListItemIcon>
                        <ManageAccounts fontSize="small" />
                    </ListItemIcon>
                    Manage Profile
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </Stack>
    );
}
