import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import { useCallback, useState } from 'react';
import MultiSelectListBox, { MultiSelectListBoxItem } from './MultiSelectListBox';

interface TransferListProps {
    leftItems: MultiSelectListBoxItem[];
    rightItems: MultiSelectListBoxItem[];
    onTransferToLeft?: (transferItems: MultiSelectListBoxItem[]) => void;
    onTransferToRight?: (transferItems: MultiSelectListBoxItem[]) => void;
    minListWidth?: number;
    minListHeight?: number;
    maxListHeight?: number;
    leftLabel?: string;
    rightLabel?: string;
    disabled?: boolean;
}

type ListBoxDirection = 'Left' | 'Right';

export default function TransferList({
    leftItems,
    rightItems,
    minListWidth,
    minListHeight,
    maxListHeight,
    leftLabel,
    rightLabel,
    disabled,
    onTransferToLeft,
    onTransferToRight,
}: TransferListProps) {
    const [leftSelectedItems, setLeftSelectedItems] = useState<MultiSelectListBoxItem[]>(new Array<MultiSelectListBoxItem>());
    const [rightSelectedItems, setRightSelectedItems] = useState<MultiSelectListBoxItem[]>(new Array<MultiSelectListBoxItem>());

    const handleOnChange = useCallback((currentlySelectedItems: MultiSelectListBoxItem[], side: ListBoxDirection) => {
        if (side === 'Left') setLeftSelectedItems([...currentlySelectedItems]);
        else setRightSelectedItems([...currentlySelectedItems]);
    }, []);

    const handleTransfer = useCallback(
        (direction: ListBoxDirection) => {
            if (direction === 'Left' && onTransferToLeft) onTransferToLeft(rightSelectedItems);
            else if (direction === 'Right' && onTransferToRight) onTransferToRight(leftSelectedItems);
        },
        [leftSelectedItems, rightSelectedItems, onTransferToLeft, onTransferToRight]
    );

    return (
        <Stack direction="row" spacing={4} justifyContent="center">
            <MultiSelectListBox
                label={leftLabel}
                items={leftItems}
                disabled={disabled}
                minWidth={minListWidth}
                minHeight={minListHeight}
                maxHeight={maxListHeight}
                onChange={(selectedItems) => handleOnChange(selectedItems, 'Left')}
            />
            <Stack direction="column" sx={{}} justifyContent="center" spacing={4}>
                <Button disabled={disabled} variant="outlined" size="small" onClick={() => handleTransfer('Right')}>
                    <ChevronRight />
                </Button>
                <Button disabled={disabled} variant="outlined" size="small" onClick={() => handleTransfer('Left')}>
                    <ChevronLeft />
                </Button>
            </Stack>
            <MultiSelectListBox
                label={rightLabel}
                items={rightItems}
                disabled={disabled}
                minWidth={minListWidth}
                minHeight={minListHeight}
                maxHeight={maxListHeight}
                onChange={(selectedItems) => handleOnChange(selectedItems, 'Right')}
            />
        </Stack>
    );
}
