import { Stack, Typography } from '@mui/material';

export function Forbidden() {
    return (
        <Stack direction="column" justifyContent="space-between" spacing={4} alignItems="center" sx={{ mt: 20 }}>
            <Typography variant="h3">Ah ah ah, you didn't say the magic word.</Typography>
            <Typography variant="h4">(You don't have permission to access this page)</Typography>
        </Stack>
    );
}
