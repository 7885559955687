import { configureStore } from '@reduxjs/toolkit';
import currentUserReducer from './currentUserSlice';
import settingsReducer from './settingsSlice';
import gameDataReducer from './gameDataSlice';

const store = configureStore({
    reducer: {
        currentUser: currentUserReducer,
        settings: settingsReducer,
        gameData: gameDataReducer,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
