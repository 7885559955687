import { random } from '@khel/kutils';
import Constants from './constants';

/**
 * Generates a random token of the desired length. NOTE: Not cryptographically strong.
 * @param {number} length
 * @returns {string}
 */
function generateToken(length: number): string {
    const possibleCharacters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'.split('');
    const tokenArray = [];

    for (let i = 0; i < length; i++) {
        tokenArray.push(possibleCharacters[random(0, possibleCharacters.length - 1)]);
    }

    return tokenArray.join();
}

export type IgdbImageSize = 'cover_small' | 'screenshot_med' | 'cover_big' | 'logo_med' | 'screenshot_big' | 'screenshot_huge' | 'thumb' | 'micro' | '720p' | '1080p';

export function igdbImageUrl(imageId: string | undefined, size: IgdbImageSize): string {
    if (!imageId) return '';

    return `${Constants.igdbImageCdn}igdb/image/upload/t_${size}/${imageId}.jpg`;
}

export { generateToken };
