import { Devices, EmojiEvents, Event, PersonAdd, ManageAccounts } from '@mui/icons-material';
import { SvgIcon } from '@mui/material';

interface DrawerMenuItem {
    text: string;
    icon: typeof SvgIcon;
    route: string;
}

interface DrawerMenuGroup {
    heading: string;
    needsAdmin?: boolean;
    items: DrawerMenuItem[];
}

export const menuGroups: DrawerMenuGroup[] = [
    { heading: 'Admin', needsAdmin: true, items: [{ text: 'Edit Platforms', route: '/admin/platforms', icon: Devices }] },
    {
        heading: 'Events',
        items: [
            { text: 'My Summary', route: '/admin/platforms', icon: EmojiEvents },
            { text: 'Host a Caper', route: '/events/create', icon: Event },
        ],
    },
    {
        heading: 'Buddies',
        items: [
            { text: 'Find Buddies', route: '/friends/find', icon: PersonAdd },
            { text: 'Manage Buddies', route: '/friends/manage', icon: ManageAccounts },
        ],
    },
];
