import { useCallback, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box } from '@mui/material';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { ChallengeResponse } from '@functions/types';
import { getApp } from 'firebase/app';
import DiscordIcon from '../icons/DiscordIcon';

export default function DiscordLoginButton() {
    const [isLoading, setIsLoading] = useState(false);

    const discordLoginButtonClick = useCallback(() => {
        async function fetchChallengeUrl() {
            try {
                const functions = getFunctions(getApp());
                const discordChallenge = httpsCallable<null, ChallengeResponse>(functions, 'discordChallengeV2');

                const response = await discordChallenge();

                if (response.data.challengeUrl !== '') window.location.href = response.data.challengeUrl;
            } catch (error) {
                console.warn('Error while retrieving challenge url:', error);
                setIsLoading(false); // Only turn off loading if theres an error, on success we'll redirect so it wont matter
            }
        }

        setIsLoading(true);
        fetchChallengeUrl();
    }, []);

    return (
        <Box>
            <LoadingButton variant="contained" loading={isLoading} startIcon={<DiscordIcon />} loadingPosition="start" onClick={discordLoginButtonClick}>
                Login With Discord
            </LoadingButton>
        </Box>
    );
}
