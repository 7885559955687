import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';

interface SettingsState {
    drawerOpen: boolean;
}

const initialState: SettingsState = {
    drawerOpen: false,
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        openDrawer: (state) => {
            state.drawerOpen = true;
        },

        closeDrawer: (state) => {
            state.drawerOpen = false;
        },
    },
});

export const { openDrawer, closeDrawer } = settingsSlice.actions;
export const selectDrawerOpen = (state: RootState) => state.settings.drawerOpen;

export default settingsSlice.reducer;
