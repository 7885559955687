/* eslint-disable no-unsafe-optional-chaining */
import { hasValue, sortBy } from '@khel/kutils';
import { UserAccountData, UserProfile } from '@functions/types';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, FormControlLabel, FormGroup, Grid, Paper, Stack, Switch, TextField, Typography } from '@mui/material';
import { getTimeZones } from '@vvo/tzdb';
import { getApp } from 'firebase/app';
import { getAuth, reload, updateProfile } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import IconHelperText from 'src/components/IconHelperText';
import { selectUserAccountData, selectUserProfile, selectUserStatus, updateUserAccountData } from 'src/store/currentUserSlice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useFirestoreCollection } from 'src/hooks/firestoreHooks';
import { Forbidden } from '../shared/Forbidden';

interface TimezoneDetails {
    id: string;
    timezoneName: string;
    country: string;
    currentFormat: string;
    commonName: string;
    abbreviation: string;
    offset: number;
    groupBy: string;
    mainCities: string[];
}

const timezoneList: TimezoneDetails[] = new Array<TimezoneDetails>();

const timezones = sortBy(getTimeZones(), ['alternativeName', 'currentTimeOffsetInMinutes', 'countryName']);
timezones.forEach((timezone) => {
    timezone.group.forEach((timezoneName) => {
        const newTimezone: TimezoneDetails = {
            timezoneName,
            id: `${timezoneName}_${timezone.countryCode}`,
            country: timezone.countryName,
            currentFormat: timezone.currentTimeFormat,
            commonName: timezone.alternativeName,
            groupBy: `${timezone.alternativeName} - ${timezone.countryName} (UTC ${timezone.currentTimeOffsetInMinutes >= 0 ? '+' : ''}${
                timezone.currentTimeOffsetInMinutes / 60
            })`,
            abbreviation: timezone.abbreviation,
            offset: timezone.currentTimeOffsetInMinutes,
            mainCities: timezone.mainCities,
        };

        timezoneList.push(newTimezone);
    });
});

sortBy(timezoneList, ['offset', 'commonName', 'timezoneName']);

export default function ManageProfile() {
    const currentUser = useAppSelector(selectUserAccountData);
    const userStatus = useAppSelector(selectUserStatus);
    const userProfile = useAppSelector(selectUserProfile);

    const dispatch = useAppDispatch();
    const [displayName, setDisplayName] = useState(currentUser?.displayName);
    const [localTimezone, setlocalTimezone] = useState(timezoneList.find((timezone) => timezone.timezoneName === userProfile?.timezone) || null);
    const [darkMode, setDarkMode] = useState(userProfile?.siteOptions.theme === 'dark');
    const [isChanged, setIsChanged] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const userProfilesCollection = useFirestoreCollection<UserProfile>('userProfiles');

    const handleTextFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setDisplayName(event.target.value);
        setIsChanged(true);
    }, []);

    const displayNameError = useMemo(() => !hasValue(displayName) || displayName.trim() === '', [displayName]);

    const handleTimezoneChange = useCallback((event: React.SyntheticEvent<Element, Event>, newValue: TimezoneDetails | null) => {
        setlocalTimezone(newValue);
        setIsChanged(true);
    }, []);

    const timezoneError = useMemo(() => !hasValue(localTimezone), [localTimezone]);

    const handleDarkModeSwitch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setDarkMode(event.target.checked);
        setIsChanged(true);
    }, []);

    const handleSaveChanges = useCallback(() => {
        async function saveChanges() {
            // This shouldn't ever happen, but just to be safe we'll check
            if (!hasValue(currentUser) || !hasValue(userProfile)) return;

            // If theres errors, don't save
            if (displayNameError || timezoneError) return;

            setIsSaving(true);

            try {
                // Update the display name in the user's profile
                await updateProfile(getAuth(getApp()).currentUser!, { displayName });
                await reload(getAuth(getApp()).currentUser!);

                // Update the user's profile
                const updatedUserProfile: UserProfile = {
                    uid: currentUser.uid,
                    timezone: localTimezone!.timezoneName,
                    siteOptions: { theme: darkMode ? 'dark' : 'light' },
                };

                await setDoc(doc<UserProfile>(userProfilesCollection, updatedUserProfile.uid), updatedUserProfile);

                enqueueSnackbar('Successfully updated your profile.', { variant: 'success' });
                dispatch(updateUserAccountData(getAuth(getApp()).currentUser!.toJSON() as UserAccountData));
                setIsChanged(false);
            } catch (error: any) {
                console.error('Error while saving profile changes', error.toJSON() ? error.toJSON() : { ...error });
                enqueueSnackbar('An error occured while trying to save your profile changes.', { variant: 'error' });
            } finally {
                setIsSaving(false);
            }
        }

        saveChanges();
    }, [currentUser, darkMode, displayName, localTimezone, userProfile, displayNameError, timezoneError, enqueueSnackbar, dispatch, userProfilesCollection]);

    if (userStatus !== 'SignedIn') return <Forbidden />;

    return (
        <Grid container columns={3}>
            <Grid xs item />
            <Grid xs="auto" item>
                <Stack direction="column">
                    <Typography gutterBottom variant="h4" sx={{ mb: 4 }}>
                        Manage Profile
                    </Typography>
                    <Typography gutterBottom variant="h5">
                        User details
                    </Typography>
                    <Paper elevation={3} sx={{ width: 500, padding: 2 }}>
                        <Stack direction="column">
                            <TextField
                                fullWidth
                                id="display-name-textfield"
                                required
                                error={displayNameError}
                                helperText={displayNameError ? 'This field is required' : ' '}
                                label="Display Name"
                                value={displayName}
                                onChange={handleTextFieldChange}
                            />
                            <TextField
                                fullWidth
                                disabled
                                id="email-textfield"
                                helperText={<IconHelperText text="This field comes from Discord and cannot be edited" />}
                                label="Email"
                                InputProps={{ readOnly: true }}
                                value={currentUser?.email}
                            />
                        </Stack>
                    </Paper>
                    <Typography gutterBottom variant="h5" sx={{ mt: 4 }}>
                        Site Settings
                    </Typography>
                    <Paper elevation={3} sx={{ width: 500, padding: 2 }}>
                        <Stack direction="column">
                            <Autocomplete
                                value={localTimezone}
                                onChange={handleTimezoneChange}
                                isOptionEqualToValue={(option, value) => option.timezoneName === value.timezoneName}
                                id="timezone-autocomplete"
                                options={timezoneList}
                                groupBy={(option) => option.groupBy}
                                getOptionLabel={(option) => option.timezoneName}
                                fullWidth
                                renderInput={(params) => (
                                    <TextField
                                        error={timezoneError}
                                        helperText={
                                            timezoneError
                                                ? 'This field is required'
                                                : `${localTimezone!.commonName} (UTC ${localTimezone!.offset >= 0 ? '+' : ''}${localTimezone!.offset / 60})`
                                        }
                                        {...params}
                                        label="Timezone"
                                    />
                                )}
                            />

                            <FormGroup sx={{ ml: 1.5, mt: 3 }}>
                                <FormControlLabel label="Dark Mode" control={<Switch checked={darkMode} onChange={handleDarkModeSwitch} />} />
                            </FormGroup>
                        </Stack>
                    </Paper>
                    <LoadingButton onClick={handleSaveChanges} loading={isSaving} variant="contained" disabled={!isChanged} sx={{ alignSelf: 'flex-end', width: '150px', mt: 6 }}>
                        Save Changes
                    </LoadingButton>
                </Stack>
            </Grid>
            <Grid xs item />
        </Grid>
    );
}
